import React from "react";
import Slider from "react-slick";
import data from "../data/chapterPreview.json";

const ChapterPreviewV2 = ({ isBg }) => {
  const { chapterPreviewv2 } = data;

  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  return (
    // <!-- ========== Chapter preview section start ========== -->
    <section
      id="chapters"
      className={`section-padding chapter-preview ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
      <div className="container">
        {/* <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{chapterPreviewv2.title}</span>
              <h2 className="display-6">{chapterPreviewv2.subtitle}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div> */}
        <div
          className="chapter__preview2"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <Slider {...settings} className="chapter__preview2-carousel">
            {chapterPreviewv2.chapters.map((data, i) => (
              <div key={i} className="chapter-slide">
                <div
                  className="chapter-slide-bg"
                  style={{
                    backgroundImage: `url(${data.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "650px",
                  }}
                >
                  {/* <div className="chapter-slide-content text-center">
                    <h3 className="text-light">{data.title}</h3>
                  </div> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
    // <!-- ========== Chapter preview section end ========== -->
  );
};

export default ChapterPreviewV2;
