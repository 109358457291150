import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const fetchArts = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arts`);
    console.log(data); // Log the response to check its format
    return data;
  } catch (error) {
    console.error("Error fetching arts:", error);
    throw new Error("Failed to fetch arts");
  }
};

const Achievev3 = ({ isBg }) => {
  const { data: arts, isLoading, isError } = useQuery("arts", fetchArts);

  const [show, setShow] = useState(false);
  const [selectedArt, setSelectedArt] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (art) => {
    setSelectedArt(art);
    setShow(true);
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching arts</p>;

  return (
    <section
      id="arts"
      className={`achieve3 section-padding ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">Featured Arts</h2>
                  <p className="text-muted mb-5 fs-5">Explore our latest pieces</p>
                </div>
              </div>
            </div>
          </div>

          {/* Display fetched arts */}
          <div className="col-lg-6 mb-4">
            <div className="row">
              {Array.isArray(arts) && arts.length > 0 ? (
                arts.map((art, index) => (
                  <div
                    key={art?.id}
                    className="col-md-6 mb-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay={`${200 + index * 100}`}
                  >
                    <div className="achieve3__item h-100 translateEffect1">
                      <div className="m-20px-b">
                        <img
                          className="img-fluid"
                          src={`${process.env.REACT_APP_API_URL}${art?.image}`}
                          alt={art?.title}
                          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                        />
                      </div>
                      <h3 className="m-15px-b">{art?.title}</h3>
                      <p>Price: ${art?.price}</p>
                      <p
                        onClick={() => handleShow(art)}
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      >
                        View Details
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No arts available.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Art Details */}
      {selectedArt && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedArt.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={`${process.env.REACT_APP_API_URL}${selectedArt?.image}`}
              alt={selectedArt.title}
              className="img-fluid mb-3"
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
            <p><strong>Description:</strong> {selectedArt.description}</p>
            <p><strong>Price:</strong> ${selectedArt.price}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">
              Purchase
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default Achievev3;
