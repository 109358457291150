import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

const stripePromise = loadStripe("pk_test_51Q1IRB2MpSFY4EXUBcCrOo6mA8ZSB0qgt3qf6dIgLC1cYAv1juTKRDTnhlZapkyrjw7ViFdXSVOMuXUjaAiRxEs800MIfsdVge");

const PaymentModal = ({ isOpen, onClose, book }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [processing, setProcessing] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError(null);
    setSuccess(null);

    if (!stripe || !elements) {
      setError("Stripe not loaded yet.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
      // axios.defaults.withCredentials = true;
      const { data: { clientSecret } } = await axios.post(`${process.env.REACT_APP_API_URL}/payment`, {
        amount: book?.price * 100, // Amount in cents
        currency: "usd",
        description: `Purchase of ${book?.title}`,
      });

      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });

      if (paymentError) {
        setError(paymentError.message);
      } else if (paymentIntent.status === "succeeded") {
        setSuccess("Payment successful!");
        setTimeout(onClose, 2000); // Close modal after success
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred while processing payment.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Payment for {book?.title}</h2>
        <form onSubmit={handleSubmit} className="payment-form">
          <div className="card-input">
            <CardElement />
          </div>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <div className="button-group">
            <button
              type="button"
              onClick={onClose}
              className="cancel-button"
              disabled={processing}
            >
              Close
            </button>
            <button
              type="submit"
              disabled={processing || !stripe || !elements}
              className="pay-button"
            >
              {processing ? "Processing..." : `Pay $${book?.price}`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Wrap your app or component with Elements
export const WrappedPaymentModal = (props) => (
  <Elements stripe={stripePromise}>
    <PaymentModal {...props} />
  </Elements>
);

export default WrappedPaymentModal;
