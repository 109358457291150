import React from "react";
import { useQuery } from "react-query";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

const fetchReviews = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/reviews`);
  return data.filter(review => review.visible === 1); // Filter for visible reviews
};

const Testimonial = ({ isBg }) => {
  const { data: reviews, isLoading, isError } = useQuery("reviews", fetchReviews);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching reviews</p>;

  return (
    // <!-- ========== Testimonial section start ========== -->
    <section
      id="reviews"
      className={`section-padding testimonial ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>Testimonials</span>
              <h2 className="display-6">What Our Customers Say</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row testi-row">
          <div className="col-12">
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              autoplay
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {reviews?.map((data) => (
                <SwiperSlide key={data.id}>
                  <div className="swiper-slide p-5px-lr">
                    <div className="testi-card card h-100 translateEffect1">
                      <div className="card-body p-4">
                        <div className="testi-card__quotation">
                          <i className="icofont-quote-right"></i>
                        </div>
                        <p className="my-4">{data.review}</p>
                        <div className="testi-card__user-info pt-4">
                          {/* <div className="testimonial__user-info__image">
                            <img src={data.image} alt={data.name} />
                          </div> */}
                          <div className="testimonial__user-info__content">
                            <h4 className="mb-0">{data.name}</h4>
                            <p className="mb-0">{data.title}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Testimonial section end ========== -->
  );
};

export default Testimonial;
