import React, { useEffect } from "react";
import GLightbox from "glightbox";
import data from "../data/hero.json";

const Herov3 = ({ isBg }) => {
  const { herov3 } = data;

  useEffect(() => {
    GLightbox({
      selector: ".glightbox3",
    });
  }, []);

  return (
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >

        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="hero__content position-relative">
              <div className="badge-text mb-2 text-uppercase">
                {herov3.subtitle}
              </div>
              <h1 className="display-4 mb-4 text-capitalize">{herov3.title}</h1>
              <p className="mb-5 fs-5">{herov3.description}</p>
              
            </div>
          </div>
          {/* Right Image */}
          <div className="col-lg-6">
            <div className="hero__author text-center">
              {/* <div className="hero__author--inner3"> */}
                {/* <div className="hero__author--inner3--wrapper"> */}
                  <img
                    width="800"
                    className="img_bloom"
                    src={herov3.image}
                    alt={herov3.title}
                  />
                </div>
              {/* </div>
            </div> */}
          </div>
        </div>
    </section>
  );
};

export default Herov3;
