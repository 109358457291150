import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WrappedPaymentModal } from "./PaymentModal"; // Import the wrapped modal

const fetchBook = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/books`);
  return data[0]; // Assuming the API returns an array with only 1 book
};

const Pricing = ({ isBg }) => {
  const { data: book, isLoading, isError } = useQuery("book", fetchBook);
  const [isModalOpen, setModalOpen] = useState(false);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching book details</p>;

  return (
    <section
      id="pricing"
      className={`section-padding pricing ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>Pricing</span>
              <h2 className="display-6">{book?.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div
            className="col-md-8 col-lg-6 mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="pricing__item translateEffect1 active">
              <h3 className="pricing__title">{book?.title}</h3>
              <h3 className="pricing__price">${book?.price}</h3>
              <ul className="pricing__list">
                <li>ISBN: {book?.isbn}</li>
                <li>Description: {book?.description}</li>
              </ul>
              <button 
                className="button button__primary" 
                onClick={() => setModalOpen(true)}
              >
                <span>Purchase</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <WrappedPaymentModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} book={book} />
    </section>
  );
};

export default Pricing;
