import "bootstrap/dist/css/bootstrap.min.css";
import "glightbox/dist/css/glightbox.css";
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/sass/main.scss";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>

    <App />
    </QueryClientProvider>
  );

