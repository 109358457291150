/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import data from "../data/contact.json";
import { socialLink } from "../global";
import { useForm, ValidationError } from '@formspree/react';

const Contact = ({ isBg }) => {
  const { contact } = data;
  const [state, handleSubmit] = useForm("manwgoaw"); // Use your Formspree form ID here

  const handleFormReset = (e) => {
    e.target.reset(); // Reset the form fields
  };

  return (
    // <!-- ========== Contact section start ========== -->
    <section
      id="contact"
      className={`p-80px-tb ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{contact.subtitle}</span>
              <h2 className="display-6">{contact.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {/* <!--  contact form area start --> */}
          <div
            className="col-lg-8 col-md-6 col-sm-12 m-25px-b"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="contact-form-box">
              <form id="contact-form" onSubmit={(e) => {
                handleSubmit(e);
                handleFormReset(e);
              }}>
                <div className="message col">
                  {state.succeeded && (
                    <p className="email-success alert alert-success">
                      <i className="icofont-check-circled"></i> Your quote has
                      successfully been sent.
                    </p>
                  )}
                  {state.errors?.length > 0 && (
                    <p className="email-failed alert alert-danger">
                      <i className="icofont-close-circled"></i> Something went
                      wrong!
                    </p>
                  )}
                </div>
                <div className="mb13">
                  <input
                    name="name"
                    className="contact-name"
                    id="contact-name"
                    type="text"
                    placeholder="Your Name"
                    required
                    style={{
                      backgroundColor: "#f0f8ff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="mb13">
                  <input
                    name="email"
                    className="contact-email"
                    id="contact-email"
                    type="email"
                    placeholder="Your Email"
                    required
                    style={{
                      backgroundColor: "#f0f8ff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
                <div className="mb13">
                  <input
                    name="subject"
                    className="contact-subject"
                    id="contact-subject"
                    type="text"
                    placeholder="Subject"
                    required
                    style={{
                      backgroundColor: "#f0f8ff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="mb30">
                  <textarea
                    name="message"
                    className="contact-message"
                    id="contact-message"
                    placeholder="Your Message"
                    required
                    style={{
                      backgroundColor: "#f0f8ff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  ></textarea>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                </div>
                <button
                  type="submit"
                  className="button button__primary align-items-center"
                  disabled={state.submitting}
                >
                  <span>{contact.btnText}</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Contact section end ========== -->
  );
};

export default Contact;
